import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Typography from "root/components/Typography";
import facebook from "root/assets/facebook.svg";
import instagram from "root/assets/instagram.svg";
import twitter from "root/assets/twitter.svg";
import linkedin from "root/assets/linkedin.svg";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import Grid from "root/components/Grid";

import styles from "./index.module.css";

const mobileW = 800;
const desktopW = 1200;

function Footer({ translations }) {
  const dimensions = useWindowDimensions();

  const renderPages = () => {
    if (dimensions.width > mobileW && dimensions.width < desktopW) {
      return (
        <div className={styles.pages}>
          <div>
            <div className={styles.page}>
              <Link
                to={translations.about.url}
                label={`Siolife's ${translations.about.label}`}
              >
                <Typography color="white">
                  {translations.about.label}
                </Typography>
              </Link>
            </div>
            <div className={styles.page}>
              <Link
                to={translations.careers.url}
                label={`Siolife's ${translations.careers.label}`}
              >
                <Typography color="white">
                  {translations.careers.label}
                </Typography>
              </Link>
            </div>
            <div className={styles.page}>
              <Typography color="white">
                {translations.termsConditions.label}
              </Typography>
            </div>
            <div className={styles.page}>
              <Typography color="white">
                {translations.privacy.label}
              </Typography>
            </div>
          </div>
          <div>
            <div className={styles.page}>
              <Link
                to={translations.productInstitution.url}
                label={`Siolife's ${translations.productInstitution.label}`}
              >
                <Typography color="white">
                  {translations.productInstitution.label}
                </Typography>
              </Link>
            </div>
            <div className={styles.page}>
              <Link
                to={translations.productHome.url}
                label={`Siolife's ${translations.productHome.label}`}
              >
                <Typography color="white">
                  {translations.productHome.label}
                </Typography>
              </Link>
            </div>
            <div className={styles.page}>
              <Link
                to={translations.productCities.url}
                label={`Siolife's ${translations.productCities.label}`}
              >
                <Typography color="white">
                  {translations.productCities.label}
                </Typography>
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.pages}>
        <div>
          <div className={styles.page}>
            <Link
              to={translations.about.url}
              label={`Siolife's ${translations.about.label}`}
            >
              <Typography color="white">{translations.about.label}</Typography>
            </Link>
          </div>
          <div className={styles.page}>
            <Link
              to={translations.careers.url}
              label={`Siolife's ${translations.careers.label}`}
            >
              <Typography color="white">
                {translations.careers.label}
              </Typography>
            </Link>
          </div>
        </div>
        <div>
          <div className={styles.page}>
            <Link
              to={translations.productInstitution.url}
              label={`Siolife's ${translations.productInstitution.label}`}
            >
              <Typography color="white">
                {translations.productInstitution.label}
              </Typography>
            </Link>
          </div>
          <div className={styles.page}>
            <Link
              to={translations.productHome.url}
              label={`Siolife's ${translations.productHome.label}`}
            >
              <Typography color="white">
                {translations.productHome.label}
              </Typography>
            </Link>
          </div>
          <div className={styles.page}>
            <Link
              to={translations.productCities.url}
              label={`Siolife's ${translations.productCities.label}`}
            >
              <Typography color="white">
                {translations.productCities.label}
              </Typography>
            </Link>
          </div>
        </div>
        <div>
          <div className={styles.page}>
            <Typography color="white">
              {translations.termsConditions.label}
            </Typography>
          </div>
          <div className={styles.page}>
            <Typography color="white">{translations.privacy.label}</Typography>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div>
          <div className={styles.gridRoot}>
            <div className={styles.grid}>
              <Grid
                color="white"
                opacity={0.3}
                columns={dimensions.width > mobileW ? 54 : 18}
                lines={dimensions.width > mobileW ? 9 : 45}
              />
            </div>
          </div>
          <Link to="/" label="Siolife's homepage">
            <Typography variant="h2" weight="medium" color="white">
              Sioslife
            </Typography>
          </Link>
          <div className={styles.social}>
            <a
              href="https://www.facebook.com/sioslife/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="facebook icon" />
            </a>
            <a
              href="https://www.instagram.com/sioslife/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="instagram icon" />
            </a>
            <a
              href="https://twitter.com/siosLIFE"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="twitter icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/sioslife/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="linkedin icon" />
            </a>
          </div>
        </div>
        {renderPages()}
      </div>
    </div>
  );
}

Footer.propTypes = {
  translations: PropTypes.shape().isRequired,
};

export default Footer;
