import React from "react";
import PropTypes from "prop-types";
import Typography from "root/components/Typography";
import AskSioslifeForm from "root/components/AskSioslifeForm";
import Button from "root/components/Button";
import { Link } from "gatsby";

import ChangeLanguage from "./ChangeLanguage";
import styles from "./menu.module.css";

const MenuMobile = ({ translations, closeMenu, buttonColor }) => {
  const renderPageLink = (name, label) => {
    const url = name === "home" ? `/` : `${name}`;

    return (
      <div className={styles.link}>
        <Link to={url} aria-label={`Sioslife's ${label}`}>
          <Typography variant="h2" color="blue">
            {label}
          </Typography>
        </Link>
      </div>
    );
  };

  return (
    <div className={styles.menu}>
      <header className={styles.header}>
        <Link to="/" label="Siolife's homepage">
          <div className={styles.sioslifeLogo}>
            <Typography variant="h2" color="blue" weight="medium">
              Sioslife
            </Typography>
          </div>
        </Link>
        <div className={styles.close}>
          <ChangeLanguage color="blue" />
          <div className={styles.button}>
            <Button smallPadding color="none" handleClick={closeMenu}>
              <Typography variant="body" color="blue">
                Close
              </Typography>
            </Button>
          </div>
        </div>
      </header>
      <nav
        className={styles.navigation}
        role="navigation"
        aria-expanded="false"
        aria-label="Navigation Menu"
      >
        {renderPageLink(translations.about.url, translations.about.label)}

        <div className={styles.products}>
          <div className={styles.title}>
            <Typography variant="h2" color="black">
              {translations.products}
            </Typography>
          </div>

          {renderPageLink(
            translations.productInstitution.url,
            translations.productInstitution.label,
          )}
          {renderPageLink(
            translations.productHome.url,
            translations.productHome.label,
          )}
          {renderPageLink(
            translations.productCities.url,
            translations.productCities.label,
          )}
        </div>

        <AskSioslifeForm
          translations={translations.askSioslifeForm}
          color={buttonColor}
        />
      </nav>
    </div>
  );
};

MenuMobile.propTypes = {
  translations: PropTypes.shape({
    about: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    productInstitution: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    productHome: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    productCities: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    stories: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    blog: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    products: PropTypes.string.isRequired,
    askSioslifeForm: PropTypes.shape().isRequired,
  }).isRequired,
  closeMenu: PropTypes.func.isRequired,
  buttonColor: PropTypes.oneOf(["yellow", "purple", "red", "green"]),
};

MenuMobile.defaultProps = {
  buttonColor: "yellow",
};

export default MenuMobile;
