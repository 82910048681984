import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.css";

const Button = ({ children, handleClick, color, type, size }) => {
  const className = classNames(
    styles.button,
    styles[color],
    size ? styles[size] : null,
  );

  /* eslint-disable react/button-has-type */
  return (
    <button type={type} className={className} onClick={handleClick}>
      {children}
    </button>
  );
  /* eslint-enable react/button-has-type */
};

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit"]),
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func,
  color: PropTypes.oneOf([
    "yellow",
    "red",
    "purple",
    "green",
    "none",
    "blue",
    "white",
  ]),
  size: PropTypes.oneOf(["xSmall", "small", "medium", "large", "full"]),
};

Button.defaultProps = {
  type: "button",
  handleClick: null,
  color: "none",
  size: "full",
};

export default Button;
