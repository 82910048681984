import React, { useCallback } from "react";
import PropTypes from "prop-types";
import i18next from "root/i18n/config";
import Typography from "root/components/Typography";
import Button from "root/components/Button";
import classNames from "classnames";

import styles from "./ChangeLanguage.module.css";

const ChangeLanguage = ({ color }) => {
  const changeLangPT = useCallback(() => {
    i18next.changeLanguage("pt");
  }, []);

  const changeLangEN = useCallback(() => {
    i18next.changeLanguage("en");
  }, []);

  return (
    <div className={styles.root}>
      <div
        className={classNames(
          styles.button,
          !i18next.language || i18next.language === "pt"
            ? styles.selected
            : null,
        )}
      >
        <Button size="xSmall" color="none" handleClick={changeLangPT}>
          <Typography variant="smallBody" color={color}>
            PT
          </Typography>
        </Button>
      </div>
      <div className={classNames(styles.line, styles[color])} />
      <div
        className={classNames(
          styles.button,
          i18next.language === "en" ? styles.selected : null,
        )}
      >
        <Button size="xSmall" color="none" handleClick={changeLangEN}>
          <Typography variant="smallBody" color={color}>
            EN
          </Typography>
        </Button>
      </div>
    </div>
  );
};

ChangeLanguage.propTypes = {
  color: PropTypes.oneOf(["white", "blue"]),
};

ChangeLanguage.defaultProps = {
  color: "white",
};
export default ChangeLanguage;
