import React from "react";
import PropTypes from "prop-types";
import times from "lodash/times";
import classNames from "classnames";

import styles from "./index.module.css";

export default function Grid({ columns, lines, color, opacity }) {
  const getColumns = () => {
    return times(columns, () => {
      const className = classNames(styles.circle, styles[color]);

      return <div className={className} />;
    });
  };

  return (
    <div style={{ opacity }}>
      {times(lines, () => {
        return <div className={styles.line}>{getColumns()}</div>;
      })}
    </div>
  );
}

Grid.propTypes = {
  columns: PropTypes.number.isRequired,
  lines: PropTypes.number.isRequired,
  color: PropTypes.oneOf(["purple", "green", "red", "yellow", "blue", "white"]),
  opacity: PropTypes.number,
};

Grid.defaultProps = {
  color: "yellow",
  opacity: 1,
};
