import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "root/components/Typography";
import Button from "root/components/Button";
import { Link } from "gatsby";
import map from "lodash/map";

import styles from "./menu.module.css";

const Menu = ({ title, submenus, color, currentPage }) => {
  const [submenu, displaySubmenu] = useState(false);

  const submenuHandler = () => {
    displaySubmenu(!submenu);
  };

  return (
    <div className={styles.root}>
      <Button color="none" handleClick={submenuHandler}>
        <Typography
          variant="body"
          color={color}
          underline={
            currentPage.search("product") !== -1 ? "smallLine" : "none"
          }
        >
          {title}
        </Typography>
      </Button>
      {submenu ? (
        <nav className={styles.nav}>
          {map(submenus, ({ label, url }) => {
            return (
              <Link to={url} aria-label={`Sioslife's ${label}`}>
                <Typography
                  variant="body"
                  color="blue"
                  underline={url === currentPage ? "small-line" : "none"}
                >
                  {label}
                </Typography>
              </Link>
            );
          })}
        </nav>
      ) : null}
    </div>
  );
};

Menu.propTypes = {
  color: PropTypes.oneOf(["white", "blue"]),
  currentPage: PropTypes.oneOf([
    "home",
    "about",
    "product-institution",
    "product-home",
    "product-cities",
    "none",
  ]).isRequired,
  title: PropTypes.string.isRequired,
  submenus: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

Menu.defaultProps = {
  color: "white",
};

export default Menu;
