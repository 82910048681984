import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "root/components/Typography";
import Button from "root/components/Button";
import AskSioslifeForm from "root/components/AskSioslifeForm";
import { Link } from "gatsby";
import withTrans from "root/i18n/withTrans";
import classNames from "classnames";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import ChangeLanguage from "./ChangeLanguage";
import Menu from "./Menu";
import MenuMobile from "./MenuMobile";

import styles from "./index.module.css";

const mobileW = 800;

const Navbar = ({ color, translations, currentPage, buttonColor }) => {
  const dimensions = useWindowDimensions();
  const [menu, displayMenu] = useState(false);

  const menuHandler = () => {
    displayMenu(true);
  };

  const handleCloseMenu = () => {
    displayMenu(false);
  };

  const renderBrand = () => {
    return (
      <Link to="/" label="Siolife's homepage">
        <div className={styles.sioslifeLogo}>
          <Typography variant="h2" weight="medium" color={color}>
            Sioslife
          </Typography>
        </div>
      </Link>
    );
  };

  const renderPageLink = (name, label) => {
    const url = name === "home" ? `/` : `${name}`;

    return (
      <Link to={url} aria-label={`Sioslife's ${label}`}>
        <Typography
          variant="body"
          color={color}
          underline={name === currentPage ? "smallLine" : "none"}
        >
          {label}
        </Typography>
      </Link>
    );
  };

  const renderNavigation = () => {
    if (dimensions.width > mobileW) {
      return (
        <nav
          className={styles.navigation}
          role="navigation"
          aria-expanded="false"
          aria-label="Navigation Menu"
        >
          {renderPageLink(translations.about.url, translations.about.label)}
          <div className={styles.products}>
            <Menu
              title={translations.products}
              submenus={[
                translations.productInstitution,
                translations.productHome,
                translations.productCities,
              ]}
              color={color}
              currentPage={currentPage}
            />
          </div>
          <div className={styles.schedule}>
            <AskSioslifeForm
              translations={translations.askSioslifeForm}
              color={buttonColor}
            />
          </div>
        </nav>
      );
    }

    return (
      <nav
        className={styles.navigationmobile}
        role="navigation"
        aria-expanded="false"
        aria-label="Navigation Menu"
      >
        <div className={styles.language}>
          <ChangeLanguage color={color} />
        </div>
        <div className={styles.button}>
          <Button color="none" handleClick={menuHandler}>
            <Typography variant="body" color={color}>
              Menu
            </Typography>
          </Button>
        </div>

        {menu ? (
          <MenuMobile
            translations={translations}
            color={color}
            currentPage={currentPage}
            closeMenu={handleCloseMenu}
            buttonColor={buttonColor}
          />
        ) : null}
      </nav>
    );
  };

  const rootClassName = classNames(styles.root, styles[color]);

  return (
    <div className={rootClassName}>
      <header className={styles.container}>
        {renderBrand()}
        {renderNavigation()}
      </header>
      {dimensions.width > mobileW ? (
        <div className={styles.language}>
          <ChangeLanguage color={color} />
        </div>
      ) : null}
    </div>
  );
};

Navbar.propTypes = {
  color: PropTypes.oneOf(["white", "blue"]),
  currentPage: PropTypes.oneOf([
    "home",
    "about",
    "product-institution",
    "product-home",
    "product-cities",
    "none",
  ]),
  buttonColor: PropTypes.oneOf(["yellow", "purple", "red", "green"]),
  translations: PropTypes.shape({
    about: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    productInstitution: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    productHome: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    productCities: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    products: PropTypes.string.isRequired,
    askSioslifeForm: PropTypes.shape().isRequired,
  }).isRequired,
};

Navbar.defaultProps = {
  currentPage: "home",
  color: "white",
  buttonColor: "yellow",
};

export default withTrans(Navbar);
