import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.css";

const InputText = ({
  placeholder,
  textColor,
  name,
  inputRef,
  type,
  handleOnChange,
  value,
  styling,
  borderColor,
}) => {
  const className = classNames(
    styles.input,
    styles[textColor],
    styles[styling],
    styles[borderColor],
  );

  return (
    <input
      placeholder={placeholder}
      type={type}
      name={name}
      ref={inputRef}
      className={className}
      onChange={handleOnChange}
      value={value}
    />
  );
};

InputText.propTypes = {
  handleOnChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  inputRef: PropTypes.func,
  textColor: PropTypes.oneOf(["black"]),
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  styling: PropTypes.oneOf(["default", "form"]),
  borderColor: PropTypes.oneOf(["yellow", "purple", "red", "green"]),
};

InputText.defaultProps = {
  handleOnChange: null,
  placeholder: "",
  name: "",
  inputRef: null,
  textColor: "black",
  type: "text",
  styling: "default",
  borderColor: "yellow",
};

export default InputText;
