import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import closeModalButton from "root/assets/closeModal.svg";
import InputText from "root/components/InputText";
import Button from "root/components/Button";
import Typography from "root/components/Typography";

import styles from "./index.module.css";

const url =
  "https://sioslife.us16.list-manage.com/subscribe/post?u=c1ad5faab6b6a44b81c810bf4&amp;id=17f0f83421";

const EmailForm = ({
  onValidated,
  closeModal,
  color,
  translations: {
    namePlaceholder,
    emailPlaceholder,
    phonePlaceholder,
    institutionNamePlaceholder,
    institutionPositionPlaceholder,
    submit,
  },
}) => {
  const { handleSubmit, register, setValue } = useForm();

  const clearForm = () => {
    setValue("name", "");
    setValue("email", "");
    setValue("phone", "");
    setValue("institutionName", "");
    setValue("institutionPosition", "");
  };

  const onSubmit = values => {
    const { name, email, phone, institutionName, institutionPosition } = values;

    if (
      name &&
      institutionName &&
      institutionPosition &&
      phone &&
      email &&
      email.indexOf("@") > -1
    ) {
      onValidated({
        EMAIL: email,
        NAME: name,
        PHONE: phone,
        INST: institutionName,
        INSTPOS: institutionPosition,
      });
    }

    clearForm();
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        role="button"
        onClick={closeModal}
        onKeyDown={closeModal}
        className={styles.closeModalButton}
        tabIndex="0"
      >
        <img alt="close button" src={closeModalButton} />
      </div>
      <InputText
        name="name"
        inputRef={register}
        type="text"
        placeholder={namePlaceholder}
        styling="form"
        borderColor={color === "blue" ? "yellow" : color}
      />
      <InputText
        name="email"
        inputRef={register}
        type="email"
        placeholder={emailPlaceholder}
        styling="form"
        borderColor={color === "blue" ? "yellow" : color}
      />
      <InputText
        name="phone"
        inputRef={register}
        type="tel"
        placeholder={phonePlaceholder}
        styling="form"
        borderColor={color === "blue" ? "yellow" : color}
      />
      <InputText
        name="institutionName"
        inputRef={register}
        type="text"
        placeholder={institutionNamePlaceholder}
        styling="form"
        borderColor={color === "blue" ? "yellow" : color}
      />
      <InputText
        name="institutionPosition"
        inputRef={register}
        type="text"
        placeholder={institutionPositionPlaceholder}
        styling="form"
        borderColor={color === "blue" ? "yellow" : color}
      />

      <Button color={color === "blue" ? "yellow" : color} type="submit">
        <Typography>{submit}</Typography>
      </Button>
    </form>
  );
};

EmailForm.propTypes = {
  onValidated: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  translations: PropTypes.shape({
    namePlaceholder: PropTypes.string.isRequired,
    emailPlaceholder: PropTypes.string.isRequired,
    phonePlaceholder: PropTypes.string.isRequired,
    institutionNamePlaceholder: PropTypes.string.isRequired,
    institutionPositionPlaceholder: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
  }).isRequired,
};

const column = 73;
const gutter = 28;
const sixColumns = 6 * column + 5 * gutter;
const blue60 = "rgba(0, 163, 255, 0.6)";

const modalStyles = {
  overlay: {
    zIndex: 3,

    padding: "0 14px",

    backgroundColor: blue60,
  },
  content: {
    position: "relative",
    top: "50%",
    right: "auto",
    bottom: "auto",
    left: "auto",

    maxWidth: `${sixColumns}px`,
    padding: "0",
    margin: "0 auto",

    border: 0,
    borderRadius: "0",

    transform: "translateY(-50%)",
  },
};

Modal.setAppElement("#___gatsby");

const AskSioslifeForm = ({ translations, color }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Button handleClick={openModal} color={color} size="medium">
        <Typography>{translations.buttonLabel}</Typography>
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Ask Sioslife form modal"
        style={modalStyles}
      >
        <div className={styles.root}>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe }) => (
              <EmailForm
                translations={translations}
                onValidated={formData => subscribe(formData)}
                closeModal={closeModal}
                color={color}
              />
            )}
          />
        </div>
      </Modal>
    </div>
  );
};

AskSioslifeForm.propTypes = {
  translations: PropTypes.shape({
    namePlaceholder: PropTypes.string.isRequired,
    emailPlaceholder: PropTypes.string.isRequired,
    phonePlaceholder: PropTypes.string.isRequired,
    institutionNamePlaceholder: PropTypes.string.isRequired,
    institutionPositionPlaceholder: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  }).isRequired,
  color: PropTypes.string,
};

AskSioslifeForm.defaultProps = {
  color: "yellow",
};

export default AskSioslifeForm;
